import React, { useEffect, useRef, Suspense } from "react";
import Credits from "./components/Credits";
import { Canvas, useThree, useFrame, extend } from "react-three-fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import Camera from './components/Camera'
import Cubes from "./components/Cubes";
import Lights from "./components/Lights";
import { Stars } from 'drei'
import bg from './body_bg.jpg'

import Environment from "./components/Environment";
extend({ OrbitControls });


const CameraControls = () => {
  // Get a reference to the Three.js Camera, and the canvas html element.
  // We need these to setup the OrbitControls class.
  // https://threejs.org/docs/#examples/en/controls/OrbitControls

  const {
    camera,
    gl: { domElement }
  } = useThree();

  // Ref to the controls, so that we can update them on every frame using useFrame
  const controls = useRef();
  useFrame(state => controls.current.update());
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      enableZoom={true}
      
    />
  );
};


function Dolly({targetX, targetY, targetZ, time}) {
  useFrame(({camera}) => {
    /* if (camera.position.z > 5)
      camera.updateProjectionMatrix(void (camera.position.z -= 0.02 ))
    if (camera.position.y > 0)
      camera.updateProjectionMatrix(void (camera.position.y -= 0.01 ))
    if (camera.position.x < 0)
      camera.updateProjectionMatrix(void (camera.position.x += 0.02 )) */
      camera.lookAt(0, 3, 0)
    if (camera.position.z > 5)
      camera.position.z -= 0.02
    if (camera.position.y > 3)
      camera.position.y -= 0.026
    if (camera.position.x < 0)
      camera.position.x += 0.03
    
      
  })
  return null
}

function App() {
  const cameraRef = useRef()
  const cameraTarget = useRef([0, 0, 0])
  const cameraPos = useRef([0, 0, 6])
  const { camera } = useThree()



  
  return (
    <>
      <Credits/>
      <Canvas 
        // style={{ backgroundImage: `url(${bg})`}}
        camera={{ fov: 65, position: [-15, 16, 15], far: 1500 }}
        >
        
        <Suspense fallback={null}>
          <CameraControls />
          <Environment />
          <Cubes />
          <Lights />
          <Stars />
          <Dolly />
        </Suspense>
        
      </Canvas>
    </>
  );
}

export default App;
