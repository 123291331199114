import React, { useEffect, useRef } from "react";

import { perspectiveCamera, useFrame, useThree } from "react-three-fiber";


function Camera(props) {
  const ref = useRef()
  const { setDefaultCamera } = useThree()
  useEffect(() => void setDefaultCamera(ref.current), [])
  useFrame(() => ref.current.updateMatrixWorld())
  return <perspectiveCamera ref={ref} {...props} />
}

export default Camera
