import React from "react";

export default () => {
  const FakeSphere = () => {
    return (
      <mesh>
        <sphereBufferGeometry args={[0.4, 10, 10]} attach="geometry" />
        <meshBasicMaterial color={0x00ffff} attach="material" />
      </mesh>
    );
  };

  return (
    <group>
      <ambientLight intensity={0.8} />
      <pointLight intensity={1.5} position={[0, 3, 0]} color={0x00ffff} />
      <pointLight intensity={1.5} position={[-7, 10, 6]} color={0xff00ff} />
    </group>
  );
};
