import React, { useRef, useMemo } from "react";
import patBott from '../../pat_bottom.jpg'
import patHorizon from '../../horizon.jpg'
import * as THREE from "three"
import { BackSide } from 'three'
import { useLoader } from 'react-three-fiber'

export default () => {
  const mesh = useRef()
  const url = '/pat_bottom.jpg'
  const [texture_bottom, texture_horizon] = useLoader(THREE.TextureLoader, [patBott, patHorizon])
  texture_bottom.wrapS = THREE.RepeatWrapping;
  texture_bottom.wrapT = THREE.RepeatWrapping;
  texture_bottom.repeat.x = 7000;
  texture_bottom.repeat.y = 700;
  texture_horizon.wrapS = THREE.RepeatWrapping;
  // texture_horizon.wrapT = THREE.RepeatWrapping;
  texture_horizon.repeat.x = 3;
  texture_horizon.repeat.y = 1;

  return (
    <group>
      <mesh
        position={[0, 399, 0]}>
        <cylinderBufferGeometry args={[1100, 1100, 850, 36]} attach="geometry" />
        <meshStandardMaterial
          // color={0xd2452b}
          map={texture_horizon}
          attach="material"
          side={THREE.BackSide}
          metalness={0.1}
          
        />
      </mesh>
      <mesh 
        ref={mesh}
        position={[0, 0, -0]}
        rotation={[-1.57, 0, -1.57]}
        >
        <planeBufferGeometry args={[20000, 2000]} attach="geometry" />
        <meshLambertMaterial
          attach="material"
          map={texture_bottom}
          metalness={0.1}
          roughness={0.8}
        />
      </mesh>
      
    </group>
  );
};
